import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'domain/services/auth/auth.service';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const selectedIdp: string = next.data?.idp;
    const roles: string[] = next.data?.roles;

    return this.authService.isDoneLoading$.pipe(
      filter(isDone => isDone),
      switchMap(_ => this.authService.isAuthenticated$),
      tap(isAuthenticated => {
        const validIdp = selectedIdp ? this.authService.hasIdpOrigin(selectedIdp) : true;
        if (!isAuthenticated || !validIdp) {
          this.authService.login(state.url, selectedIdp);
        }
      }),
      tap((isAuthenticated) => {
        if (isAuthenticated)
          this.authService.isInAtLeastOneRole(roles) || this.authService.navigateToForbidden()
      })
    );
  }

}
