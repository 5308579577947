import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from 'domain/services/auth/auth.service';
import { FullstoryService } from 'domain/services/fullstory/fullstory.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MarketplaceTenantWebApp';
  isLoading = false;

  constructor(authService: AuthService, router: Router, fullstory: FullstoryService) {
    fullstory.Initialize(environment.fullstory.isEnabled, environment.fullstory.orgId);

    //subscribe to navigation events to display loader on first page load
    let loaderSubscription = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      }
      else if (event instanceof NavigationEnd) {
        this.isLoading = false;
        //Unsubscribe to prevent loading animation to display after initial app load
        loaderSubscription.unsubscribe();
      }
    });

    authService.runInitialLoginSequence();
  }
}
