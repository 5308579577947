import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'domain/services/auth/auth.service';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent {
  constructor(route: ActivatedRoute, authService: AuthService, router: Router) {
    combineLatest([
      authService.isDoneLoading$,
      authService.isAuthenticated$,
      route.queryParams
    ]).pipe(
      filter(([isLoaded, _, __]) => isLoaded),
      take(1)
    ).subscribe(async ([_, isAuthenticated, params]) => {
      const inviteCode = params.code ?? '';
      if (inviteCode) {
        if (isAuthenticated) {
          //If an invite code is detected but the user is logged in, log them out and redirect back to the invite page
          authService.logoutWithSpecificRedirect(window.location.origin + '/invite', new HttpParams({ fromObject: params }).toString());
        }
        else {
          //Log the user in with invite code in acr_values
          authService.login('/', '', inviteCode);
        }
      }
      else {
        //Redirect user to home
        router.navigateByUrl('/');
      }
    });
  }
}
