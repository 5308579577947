import { Injectable } from '@angular/core';
import { environment } from 'projects/portal/src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Configuration, FeatureFlagService as FlagService } from 'marketplace-tenant-sdk';
import { catchError, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { FeatureModel } from 'domain/models/FeatureModel';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  public features$: Observable<FeatureModel[]>;

  constructor(private authService: AuthService, private featureFlagService: FlagService) {
    // Set flag service to use the token for auth - allows users to be identified when fetching flags
    featureFlagService.configuration = new Configuration({
      basePath: environment.tenantService.baseUrl,
      credentials: {
        Authorization: this.authService.AccessToken
      }
    });

    // Create observable that updates the flags either when the auth is updated or on a timer
    // Share replay caches the list for any subscribers
    this.features$ = combineLatest([
      this.authService.isDoneLoading$,
      timer(0, environment.tenantService.featureFlagRefreshTimeMs)
    ]).pipe(
      startWith(),
      switchMap(() => this.featureFlagService.getAllFeatureFlags().pipe(
        catchError(() => { console.error("An error occurred retrieving feature flags"); return of([]) }))
      ),
      map(featureFlags => {
        return featureFlags.map(flag => {
          const feature: FeatureModel = {
            name: flag.name,
            isEnabled: flag.isEnabled,
            lastUpdated: new Date()
          };
          return feature;
        })
      }),
      shareReplay(1)
    );
  }

  // Initialises the list of flags - if no user, will init for anonymous user
  public initialise(): Promise<void> {
    // Kick off a subscription to load features in a way that doesn't block the page load
    let initialSubscription = this.features$.subscribe(() => {
      // Unsubscribe once results are returned
      initialSubscription.unsubscribe();
    });
    return Promise.resolve();
  }

  // Create an observable that updates whenever the feature observable updates
  // Returns a boolean whether the feature is enabled or not. If the feature is not found, it will return false
  public isFeatureEnabled(featureName: string): Observable<boolean> {
    return this.features$.pipe(
      map(flags => flags?.find(x => x.name === featureName) ?? { name: featureName, isEnabled: false } as FeatureModel),
      map(x => x.isEnabled),
      shareReplay(1)
    )
  }
}
