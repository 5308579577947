// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  surgeIdentity: {
    baseUrl: 'https://internal.surgeidentity.com',
    clientId: '6918ac98-4c9a-4862-b407-b35b70c3c01b_17aa4a32-5273-432f-8ba4-2bc18ce4e691',
    scopes: 'openid profile email offline_access tenantapi roles',
    redirectUri: '/login',
    publicIdentityProvider: '48software-surge-marketplace-prod',
    adminIdentityProvider: '48software-surge-marketplace-dev'
  },
  tenantService: {
    baseUrl: 'https://apidev.surgemarketplace.com/tenant',
    featureFlagRefreshTimeMs: 30000
  },
  applicationInsights: {
    instrumentationKey: '0f0a24d9-c18c-4da1-ac23-9c61591c509c'
  },
  support: {
    email: 'support@1Nebula.com'
  },
  emailImageDefaults: {
    activateFailed: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/activation%20failed%20RED-01.png',
    activateSuccess: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/activation%20success.png',
    subscriptionCancelled: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/cancelled2-01.png',
    subscriptionReinstated: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/reinstate-02-01.png',
    subscriptionSuspended: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/suspended.png',
    planChangeInProgress: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/change%20in%20progress.png',
    planChangeFailed: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/planchangeerror-01.png',
    planChangeSuccess: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/plan%20change%20success.png',
  },
  fullstory: {
    isEnabled: false,
    orgId: 'FXWTB'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
