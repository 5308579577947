import { Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { BehaviorSubject, Observable, combineLatest, filter, startWith } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FullstoryService {
  public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private identifyObservable: Observable<[boolean, boolean]>;

  constructor(private authService: AuthService) { }

  public Initialize(isEnabled: boolean, orgId: string): void {
    // Send User identity to fullstory when the user has authenticated
    this.identifyObservable = combineLatest([
      this.isInitialized$.pipe(startWith(false)),
      this.authService.isAuthenticated$.pipe(startWith(false))
    ]).pipe(filter(([isInit, isAuth]) => isInit && isAuth));

    this.identifyObservable.subscribe(() => {
      let user = this.authService.User;
      FullStory.identify(user.sub, {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        publisherIds: user.publisherIds,
        roles: user.roles
      });
    });

    FullStory.init({
      orgId: orgId,
      devMode: !isEnabled // Full story logging will be disabled if devMode is true
    }, () => {
      this.isInitialized$.next(true);
    });
  }
}
