import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "projects/portal/src/environments/environment";

export const authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.surgeIdentity.baseUrl,

    // URL of the SPA to redirect the user to after login
    redirectUri: `${window.location.origin}`,

    postLogoutRedirectUri: `${window.location.origin}`,

    tokenEndpoint: `${environment.surgeIdentity.baseUrl}/connect/token`,

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment.surgeIdentity.clientId,

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: environment.surgeIdentity.scopes,

    showDebugInformation: true,

    nonceStateSeparator: "semicolon" // Real semicolon gets mangled by Duende ID Server's URI encoding
};