import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'projects/portal/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  applicationInsightsClient: ApplicationInsights;

  constructor() {
    // this.applicationInsightsClient = new ApplicationInsights({
    //   config: {
    //     instrumentationKey: environment.applicationInsights.instrumentationKey,
    //     enableAutoRouteTracking: true,
    //     loggingLevelConsole: 2,
    //     loggingLevelTelemetry: 2
    //   }
    // });

    // this.applicationInsightsClient.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    // this.applicationInsightsClient.trackPageView({
    //   name: name,
    //   uri: url
    // });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    // this.applicationInsightsClient.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    // this.applicationInsightsClient.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel: number = SeverityLevel.Error) {
    // this.applicationInsightsClient.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    // this.applicationInsightsClient.trackTrace({ message }, properties);
  }

}
