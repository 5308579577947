import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from '../environments/environment';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authCodeFlowConfig } from './shared/constants/authConfig';
import { initialiseFeatureFlags } from 'domain/initialisers/FeatureFlagInitializer';
import { FeatureFlagService } from 'domain/services/feature-flag/feature-flag.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return localStorage
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: [
          environment.tenantService.baseUrl
        ]
      }
    })
  ],
  providers: [
    { provide: AuthConfig, useValue: authCodeFlowConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: APP_INITIALIZER, useFactory: initialiseFeatureFlags, deps: [FeatureFlagService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
