export const redirectKey = 'loginRedirect';

export const claimKeys = {
  firstName: 'given_name',
  lastName: 'family_name',
  email: 'email',
  role: 'role',
  idp: 'idp',
  publisherId: 'publisher_id',
  sub: 'sub'
};

export const role = {
  admin: 'admin',
  publisherAdmin: 'publisherAdmin',
  publisher: 'publisher'
};
