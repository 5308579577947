import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/portal/src/environments/environment';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

  public supportEmail: string;

  ngOnInit(): void {
    this.supportEmail = `mailto:${environment.support.email}`;
  }

}
