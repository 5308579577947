import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { UserModel } from '../../models/UserModel';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';
import { claimKeys } from './auth.const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public get User(): UserModel {
    const claims = this.oauthService.getIdentityClaims();

    // Set array of publisher ids, if any
    const publisherIds = this.GetArrayFromString(claims[claimKeys.publisherId]);

    return {
      firstName: claims[claimKeys.firstName],
      lastName: claims[claimKeys.lastName],
      email: claims[claimKeys.email],
      publisherIds: publisherIds,
      roles: claims[claimKeys.role],
      sub: claims[claimKeys.sub]
    };
  }

  public get AccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  constructor(private oauthService: OAuthService, private router: Router, private loggingService: LoggingService, private authConfig: AuthConfig) {
    // Useful for debugging:
    // this.oauthService.events.subscribe(event => {
    //   if (event instanceof OAuthErrorEvent) {
    //     console.error('OAuthErrorEvent Object:', event);
    //   } else {
    //     console.warn('OAuthEvent Object:', event);
    //   }
    // });

    // Workaround for another tab updating the auth token before the current one
    // See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      if ((event.key === 'auth_data_updated' && event.newValue !== null) || event.key === null) {
        console.log('Auth data has been updated in localStorage');
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

        if (!this.oauthService.hasValidAccessToken()) {
          this.navigateToLoginPage();
        }
      }
    });

    // Update authenticated observable whenever an auth event occurs
    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    // Reload user profile when a new token is received
    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(() => this.oauthService.loadUserProfile());

    // Listen for error events - force user to re-log in
    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error', 'token_refresh_error'].includes(e.type)))
      .subscribe(() => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  private navigateToLoginPage() {
    this.oauthService.logOut();
    this.router.navigateByUrl('/');
  }

  public async runInitialLoginSequence(): Promise<void> {
    try {
      await this.oauthService.loadDiscoveryDocument();
    } catch (err) {
      this.isDoneLoadingSubject$.next(true);
      return;
    }
    await this.oauthService.tryLogin();

    this.isDoneLoadingSubject$.next(true);

    if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
      let stateUrl = this.oauthService.state;
      if (stateUrl.startsWith('/') === false) {
        stateUrl = decodeURIComponent(stateUrl);
      }

      this.router.navigateByUrl(stateUrl);
    }
  }

  // Initialise called when app is started
  public login(returnUrl?: string, idp?: string, inviteCode?: string) {
    // If an Idp has been selected, ensure it is added to the acr_values to force it to be chosen
    const acr_values = [];
    if (idp) {
      acr_values.push(`idp:${idp}`);
    }
    // Add invite code to acr_values if present
    if (inviteCode) {
      acr_values.push(`invite_code:${inviteCode}`);
    }
    //Add acr_values to auth request if any present
    if (acr_values.length > 0) {
      this.oauthService.initLoginFlow(returnUrl || this.router.url, {
        acr_values: acr_values.join(" ")
      });
      return;
    }

    this.oauthService.initLoginFlow(returnUrl || this.router.url);
  }

  public logout(redirect: boolean) {
    this.oauthService.stopAutomaticRefresh();
    this.oauthService.logOut(!redirect);
  }

  public logoutWithSpecificRedirect(postLogoutRedirectUri: string, queryString: string) {
    this.oauthService.stopAutomaticRefresh()
    if (postLogoutRedirectUri) {
      this.oauthService.postLogoutRedirectUri = postLogoutRedirectUri;
    }
    if (queryString) {
      this.oauthService.logOut({ return_query_string: queryString });
    }
    else {
      this.oauthService.logOut(false);
    }
  }

  public navigateToForbidden() {
    this.router.navigate(['/forbidden']);
  }

  public isInAtLeastOneRole(roles: string[]): boolean {
    if (!roles || roles.length < 1) {
      return true;
    }

    const claims = this.oauthService.getIdentityClaims();
    //If claims are empty, user has logged out
    if (!claims) {
      return false;
    }

    const tokenRoles: string[] = claims[claimKeys.role];
    //If no roles are found on the user, they dont have access
    if (!tokenRoles) {
      return false;
    }

    return roles.some(role => tokenRoles.includes(role));
  }

  public isInRole(role: string): boolean {
    // if the role is undefined, grant access
    if (!role) {
      return true;
    }

    const claims = this.oauthService.getIdentityClaims();
    //If claims are empty, user has logged out
    if (!claims) {
      return false;
    }

    const tokenRoles: string[] = claims[claimKeys.role];
    //If no roles are found on the user, they dont have access
    if (!tokenRoles) {
      return false;
    }

    return tokenRoles.includes(role);
  }

  public hasIdpOrigin(identityProvider: string) {
    const claims = this.oauthService.getIdentityClaims();
    if (claims) {
      const identityProviderClaim = claims[claimKeys.idp];
      return identityProviderClaim === identityProvider;
    }

    return false;
  }

  // Set value in array if of type string
  private GetArrayFromString(value: any) {
    if (value && typeof value === 'string') {
      return [value];
    } else {
      return value;
    }
  }
}
