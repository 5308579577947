import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'domain/components/login/login.component';
import { role } from 'domain/services/auth/auth.const';
import { AuthGuard } from 'domain/guards/auth/auth.guard';
import { ForbiddenComponent } from './shared/components/error/forbidden/forbidden.component';
import { NotFoundComponent } from './shared/components/error/not-found/not-found.component';
import { GenericErrorComponent } from './shared/components/error/generic-error/generic-error.component';
import { InviteComponent } from './modules/invite/invite.component';

const routes: Routes = [
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'notfound', component: NotFoundComponent },
  { path: 'login', component: LoginComponent },
  { path: 'error', component: GenericErrorComponent },
  {
    path: 'invite', loadChildren: () => import('./modules/invite/invite.module').then(m => m.InviteModule),
    component: InviteComponent
  },
  {
    path: '',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      roles: [role.admin, role.publisherAdmin, role.publisher],
    }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
